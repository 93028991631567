import { useState, useEffect, useRef } from "react";

import { Carousel } from "primereact/carousel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputMask } from "primereact/inputmask";
import { ProgressBar } from "primereact/progressbar";

import ReactPlayer from "react-player";

import "./IntroScreen_asu.css";
import "./IntroScreen_default.css";
import "./IntroScreen_13inche.css";
import "./IntroScreen_mobile.css";
import Footer from "../../shared_components/Footer/Footer";
import { InputText } from "primereact/inputtext";

import config from "../../config.json";
import amplitude from "amplitude-js";
import axios from "axios";
import { QuoteService } from "../../services/QuoteService";
import { Password } from "primereact/password";

export default function IntroScreen(props) {
  amplitude.getInstance().init(config.AMPLITUDE_KEY);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressLabel, setSelectedAddressLabel] = useState("");
  const applyGoogleMaps = () => {
    const input = document.getElementById("address-field");
    const options = {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    };
    const autocomplete = new window.google.maps.places.Autocomplete(
      input,
      options
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      let address1 = "";
      let postcode = "";
      const _selectedAddress = {};

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            _selectedAddress[componentType] = component.long_name;
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case "route": {
            _selectedAddress[componentType] = component.short_name;
            address1 += component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            _selectedAddress[componentType] = component.long_name;
            break;
          }
          case "locality":
            _selectedAddress[componentType] = component.long_name;
            break;
          case "administrative_area_level_1": {
            _selectedAddress["state"] = component.long_name;
            _selectedAddress["state_code"] = component.short_name;
            break;
          }
          case "administrative_area_level_2": {
            _selectedAddress["county"] = component.long_name;
            break;
          }
          case "neighborhood": {
            _selectedAddress["neighborhood"] = component.long_name;
            break;
          }
          case "country":
            _selectedAddress[componentType] = component.long_name;
            break;
          default:
        }

        _selectedAddress["postal_code"] = postcode;
        _selectedAddress["address_line1"] = address1;
      }

      amplitude.getInstance().logEvent("Address Selected", _selectedAddress);
      _selectedAddress.googleData = place;
      setSelectedAddress(_selectedAddress);
      setSelectedAddressLabel(
        `${(_selectedAddress || {}).address_line1}, ${
          ((_selectedAddress || {}).locality ||
            (_selectedAddress || {}).county) +
          ", " +
          (_selectedAddress || {}).state
        } - ${(_selectedAddress || {}).postal_code}`
      );
      input.value = "";
    });
  };

  useEffect(() => {
    if ((props.mode || "ECOMMERCE") === "LP") {
      amplitude
        .getInstance()
        .logEvent("Landing Page Loaded", { source: props.source });
    } else {
      amplitude.getInstance().logEvent("Intro Screen Loaded", {});
    }

    applyGoogleMaps();
  }, [props.mode, props.source]);

  const selectAddress = async () => {
    if (selectedAddress) {
      await props.onAddressSelected(selectedAddress);
    }
  };

  // Landing page mode features
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [submited, setSubmited] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saving, setSaving] = useState(false);

  const cleanPhone = (phone) => {
    return phone
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split(" ")
      .join("")
      .split("_")
      .join("")
      .split("-")
      .join("");
  };

  const isValidPhone = (phone) => {
    return (phone || "").length === 10;
  };

  const isValidEmail = (email) => {
    if (
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  const isDataValid = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      mobile === "" ||
      !isValidPhone(mobile) ||
      email === "" ||
      !isValidEmail(email)
    ) {
      return false;
    }
    return true;
  };

  const save = async () => {
    try {
      setSuccess(false);
      setSubmited(true);
      if (isDataValid()) {
        setSaving(true);
        amplitude.setUserId(email);
        amplitude
          .getInstance()
          .setUserProperties({
            firstName,
            lastName,
            mobile,
            email,
            source: props.source,
          });
        const data = (
          await axios.post(config.LEADS_API_URL, {
            first_name: firstName,
            last_name: lastName,
            mobile,
            email,
            source: props.source,
          })
        ).data;
        console.log(data);
        amplitude
          .getInstance()
          .logEvent("Form Submitted", {
            firstName,
            lastName,
            mobile,
            email,
            source: props.source,
          });
        setFirstName("");
        setLastName("");
        setMobile("");
        setEmail("");
        setSaving(false);
        setSuccess(true);
        setSubmited(false);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      amplitude.getInstance().logEvent("Page Error", error);
      setSaving(false);
      console.log(error);
    }
  };

  // -------------------------------

  /// Video
  const videoPlayer = useRef(null);
  const [playing, setPlaying] = useState(false);

  // ---

  // Login
  const [loginOpen, setLoginOpen] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [authenticating, setAuthenticating] = useState(false);
  const doLogin = async () => {
    if (login !== "" && password !== "") {
      try {
        setErrorMessage("");
        setAuthenticating(true);
        const service = new QuoteService();
        const quote = await service.login(login, password);
        if (quote && quote.id) {
          props.onAuthenticate(quote);
          setAuthenticating(false);
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log(error);
        amplitude.getInstance().logEvent("Error", {
          action: "doLogin",
          data: login,
          err: error,
        });
        setErrorMessage("Invalid credentials");
        setAuthenticating(false);
      }
    }
  };
  // ---

  return (
    <div className="screen intro">
      {false && (props.mode || "ECOMMERCE") === "ECOMMERCE" && (
        <div className="fixed-header">
          <div className={`login ${loginOpen ? "open" : "closed"}`}>
            <div className="title" onClick={() => setLoginOpen(!loginOpen)}>
              ALREADY HAVE A QUOTE?{" "}
              <i
                className={`pi ${
                  loginOpen ? "pi-angle-down" : "pi-angle-right"
                }`}
              ></i>
            </div>
            {loginOpen && (
              <>
                <InputText
                  type="text"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  placeholder="E-mail or Phone #"
                />
                <Password
                  feedback={false}
                  toggleMask={true}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <div
                  onClick={() => doLogin()}
                  className={`button ${
                    login === "" || password === "" || authenticating
                      ? "disabled"
                      : ""
                  }`}
                ></div>
                {errorMessage !== "" && (
                  <div className="error-message">{errorMessage}</div>
                )}
                {authenticating && (
                  <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px" }}
                  ></ProgressBar>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <div className="section address-bar">
        <div className="logo"></div>
        <div className="title first-line">
          {props.isArizona ? "Get The Best" : "Get the Smartest Solar"}
        </div>
        <div className="title second-line">
          {props.isArizona ? "Solar Quote Here" : "with $0 Down"}
        </div>
        {(props.mode || "ECOMMERCE") === "ECOMMERCE" && (
          <>
            <div className="sub-title">
              {props.isArizona ? (
                "Enter your address and get a free solar proposal."
              ) : (
                <>
                  You could save 100%+ on your energy bill with Elevation solar.
                  <br />
                  Start now with an instant solar savings proposal.
                </>
              )}
            </div>
            <div className="address-form">
              <div className="text-field">
                <input
                  id="address-field"
                  type="text"
                  placeholder="Enter Your Home Address"
                  value={selectedAddressLabel}
                  onChange={(e) => {
                    setSelectedAddressLabel(e.target.value);
                    setSelectedAddress(null);
                  }}
                />
              </div>
              { props.isArizona ? (
                <div
                  className={`button ${selectedAddress ? "" : "disabled"}`}
                  onClick={selectAddress}
                ></div>
              ) : (
                <div
                  className={`button ${selectedAddress ? "" : "disabled"}`}
                  onClick={selectAddress}
                >
                  GET STARTED →
                </div>
              )}
            </div>
          </>
        )}
        {(props.mode || "ECOMMERCE") === "LP" && !success && (
          <>
            <div className="sub-title">Schedule Your Consultation</div>
            <div className="lp-form">
              <div className="field">
                <div className="title">First Name</div>
                <div className="text_field">
                  <InputText
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Enter Your First Name"
                  />
                </div>
                {submited && firstName === "" && (
                  <div className="error_message">This field is mandatory</div>
                )}
              </div>
              <div className="field">
                <div className="title">Last Name</div>
                <div className="text_field">
                  <InputText
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Enter Your Last Name"
                  />
                </div>
                {submited && lastName === "" && (
                  <div className="error_message">This field is mandatory</div>
                )}
              </div>
              <div className="field">
                <div className="title">Phone Number</div>
                <div className="text_field">
                  <InputMask
                    autoClear={false}
                    mask="(999) 999-9999"
                    value={mobile || ""}
                    unmask={true}
                    onChange={(e) => setMobile(cleanPhone(e.value))}
                    placeholder="Enter Your Phone Number"
                  ></InputMask>
                </div>
                {submited && mobile === "" && (
                  <div className="error_message">This field is mandatory</div>
                )}
                {submited && mobile !== "" && !isValidPhone(mobile) && (
                  <div className="error_message">Invalid phone number</div>
                )}
              </div>
              <div className="field">
                <div className="title">Email</div>
                <div className="text_field">
                  <InputText
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email Address"
                  />
                </div>
                {submited && email === "" && (
                  <div className="error_message">This field is mandatory</div>
                )}
                {submited && email !== "" && !isValidEmail(email) && (
                  <div className="error_message">Invalid email</div>
                )}
              </div>
              <div
                id="lp-conversion"
                className="button"
                onClick={() => save()}
                style={saving ? { opacity: 0.5, cursor: "default" } : {}}
              >
                <span>Submit</span>
                <span>
                  <i className="pi pi-arrow-right"></i>
                </span>
              </div>
            </div>
          </>
        )}
        {(props.mode || "ECOMMERCE") === "LP" && success && (
          <>
            <div className="sub-title">
              Thanks for getting in touch. Our team will contact you soon.
            </div>
          </>
        )}
      </div>
      <div className="section testimonials">
        <div className="title">Don't just take our word for it.</div>

        <div className="testimonial">
          <div className="player">
            {!playing && (
              <div
                className="player-button"
                onClick={() => setPlaying(true)}
              ></div>
            )}
            <ReactPlayer
              width="100%"
              height="100%"
              ref={videoPlayer}
              playing={playing}
              onPause={() => setPlaying(false)}
              controls={playing}
              url={"/videos/video1.mp4"}
            />
          </div>
          <div className="content">
            <div className="quote-img"></div>
            <div className="text">
              It starts to pay for itself in a matter of just a few years and
              ends up being a very solid, long term investment.
            </div>
            <div className="reference">
              RECENT CUSTOMER
              <br />
              FROM GILBERT, AZ
            </div>
          </div>
        </div>
        <div className="reviews">
          <Carousel
            circular={true}
            value={[
              {
                content:
                  "I've had solar for over a year and it has been an excellent investment. Most of my bills are close to zero and im avoiding inflation. I added a Tesla recently and saving even more on the gas cost. Elevation was effective and did a great job. I reccomend.",
                author: "alexandra mosichuk",
              },
              {
                content:
                  "Elevation had great communication and took care of everything. They really worked to make the process easy. From HOA reviews, permitting, and power company coordination, they were always on top of it and provided updates along the way. I would definitely use Elevation again.",
                author: "Brian Janski",
              },
              {
                content:
                  "I am very satisfied with Elevation’s performance as a vendor. The system was delivered on time and it has been working without any issues for close to three years. The production level has been as expected and the company has been following the system performance along the way. I would recommend Elevation to anyone looking at solar panel system vendors without hesitation.",
                author: "Luis Conti",
              },
              {
                content:
                  "Going on 4 years with my solar panels installed & very happy. They have an app where you can see how much the system is producing and & I love looking at how much I’m generating/using. As cool as that is, best part is having a low bill!",
                author: "Nalleli H.",
              },
              {
                content:
                  "I like the feeling that my utility bill will never be outrageously high again. I have a lot of my neighbors complain about the high energy bills they receive each summer, so I like the fact that I’m saving money and paying less than my neighbors. Elevation install crews were great, and I can call in for support whenever I need a question answered.",
                author: "James Williams",
              },
            ]}
            itemTemplate={(review) => (
              <>
                <div className="review">
                  <div className="text">{review.content}</div>
                  <div className="author">{review.author}</div>
                  <div className="stars">
                    <i className="pi pi-star-fill"></i>
                    <i className="pi pi-star-fill"></i>
                    <i className="pi pi-star-fill"></i>
                    <i className="pi pi-star-fill"></i>
                    <i className="pi pi-star-fill"></i>
                  </div>
                </div>
              </>
            )}
          ></Carousel>

          <div className="logos">
            <div className="google-logo"></div>
            <div className="best-company">
              <a href="https://bestcompany.com">
                <img
                  src="//images.bestcompany.com/bcbs/BestBadge/2022/badge/ckawXX67GZ0RwUWxKOgl6XS7FKmy9NsDuGZHD9nx.png"
                  alt="badge"
                />
              </a>
            </div>
            <div className="best-company">
              <a href="https://bestcompany.com">
                <img
                  src="//images.bestcompany.com/bcbs/BestBadge/2022/badge/sueQwgkV55AcH5roEEHG7yZMKOjagvZkmY6RjH3c.png"
                  alt="badge"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section why-solar">
        <div className="title">Why go solar?</div>
        <div className="sub-title">
          Yes, solar is the best and most sustainable form of energy to be used
          in your propery. But we know, it’s not enough to know that. Let’s find
          answers to your other questions about Solar.
        </div>
        <Accordion multiple={true}>
          <AccordionTab header="What are the benefits of a whole-home solution?">
            A fully Elevated Home equips your home for the future. It not only
            produces new, clean energy, but it is smarter too: reducing the
            overall energy needed.
          </AccordionTab>
          <AccordionTab header="How does solar lower my electric bill?">
            Your solar system will produce energy during the daylight hours and
            your home will then use this electricity instead of the energy
            provided by your utility company.
          </AccordionTab>
          <AccordionTab header="How many panels do I need?">
            That varies from home to home based on energy usage, solar exposure,
            and the size of the home, alongside a few other factors.
          </AccordionTab>
          <AccordionTab header="How does paying for solar help control my utility costs?">
            Electric prices in the United States have been steadily increasing
            over the past 20 years. By going solar, you protect yourself against
            rising electric prices and make your monthly bills more predictable.
            With solar you produce your own energy so it takes away the
            uncertainty of fluctuating energy prices.
          </AccordionTab>
          <AccordionTab header="Can I be disconnected from the grid with a solar battery?">
            Your solar and battery system is grid-connected when the power is up
            and running. During a power outage, the battery will take over to
            temporarily run a select number of appliances but as soon as the
            utility restores power, your home is grid-connected once again.
          </AccordionTab>
        </Accordion>
      </div>
      <Footer />
    </div>
  );
}
