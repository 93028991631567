import { useEffect, useState } from "react";
import Footer from "../../shared_components/Footer/Footer";
import Header from "../../shared_components/Header/Header";

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';

import './AboutYouScreen.css';
import './AboutYouScreen.mobile.css';
import { ProgressBar } from "primereact/progressbar";
import WizardSteps from "../../components/wizard-steps/WizardSteps";

export default function AboutYouScreen(props)
{
    const [firstName, setFirstName] = useState(props.quote.person?.firstName);
    const [lastName, setLastName] = useState(props.quote.person?.lastName);
    const [email, setEmail] = useState(props.quote.person?.email);
    const [mobile, setMobile] = useState(props.quote.person?.mobile);
    
    const [roofConfirmation, setRoofConfirmation] = useState("");
    const [incorrectRoofDetail, setIncorrectRoofDetail] = useState("");
    const [incorrectRoofDetailOther, setIncorrectRoofDetailOther] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(()=>{
        const myLatLng = props.quote?.address?.googleData?.geometry?.location;
        
        const map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 20,
            center: myLatLng,
            mapTypeId: window.google.maps.MapTypeId.SATELLITE,
            disableDefaultUI: true,
            tilt: 0,
            icon: '/imgs/pin.png'
        });

        new window.google.maps.Marker({
            position: myLatLng,
            map
        });
    },[props.quote?.address?.googleData?.geometry?.location]);

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const isValidEmail = (email) => 
    {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(email))
        {
            return (true);
        }
        return (false)
    }

    const isValidPhone = (phone) => {
        return cleanPhone(phone || "").length === 10
    }

    const isDataValid = () => {
        
        if(
            (firstName || "")  === "" || 
            (lastName || "")  === "" || 
            (mobile || "")  === "" || 
            (email || "")  === ""){
            setErrorMessage("Please fill all the fields");
            return false;
        }

        if(mobile !== "" & !isValidPhone(mobile))
        {
            setErrorMessage("Please provide a valid phone number");
            return false;
        }  

        if(email !== "" & !isValidEmail(email))
        {
            setErrorMessage("Please provide a valid e-mail address");
            return false;
        }    

        return true;
    }

    const [saving, setSaving] = useState(false);
    const [showBlockMessage, setShowBlockMessage] = useState(false)
    const submitForm = async () => {
        if(!saving){
            try {
                setErrorMessage("");
                if(isDataValid()){
                    setSaving(true);
                    const _showBlockMessage = await props.onFormFilled({ firstName, lastName, email, mobile });
                    setShowBlockMessage(_showBlockMessage)
                    setSaving(false);
                }
            } catch (error) {
                setSaving(false);
                
            }
        }
    }

    const [step, setStep] = useState(0)

    return <div className={`screen about-you ${props.isArizona ? "" : "not-arizona"}`}>
        <Header/>
        
        { !props.isArizona && !showBlockMessage && <WizardSteps current={step}/>}
        { showBlockMessage && <div className="section title">
            <div className="title">
                Thank you for your interest in Elevation Solar.<br/>
                Currently we are not installing in your area but that could change soon.<br/>
                We'll keep your information on file and reach out if we move into your neighborhood!</div></div>}
        { !showBlockMessage && <div className="section title">
            <div className="title">You are one step closer to<br/>the Elevated Home Experience</div>
            <div className="sub-title">{
                (props.isArizona || step === 1) ? "Enter your information below to get your quote." : "Please confirm this is your roof"
            }</div>
        </div>}
        { (props.isArizona || step === 0 ) && !showBlockMessage && <div className="section roof-confirmation">

            <div id="map"></div>
            <div className="this-is-my-roof-button" onClick={() => { setRoofConfirmation("Yes, this is my roof"); setStep(1); }}></div>
            <div className="this-is-not-my-roof-button" onClick={() => setRoofConfirmation("No, this is not roof")}>No, it's not!</div>
            { roofConfirmation === "No, this is not roof" &&
            <div className="this-is-not-my-roof-reason">
                <span>No worries! We can help you do this later. What seems to be the problem?</span>
                <span><input type="radio" checked={incorrectRoofDetail === "My home is a new construction project"} onClick={() => setIncorrectRoofDetail("My home is a new construction project")}/>My home is a new construction project</span>
                <span><input type="radio" checked={incorrectRoofDetail === "This map tool isn't working for me"} onClick={() => setIncorrectRoofDetail("This map tool isn't working for me")}/>This map tool isn't working for me</span>
                <span><input type="radio" checked={incorrectRoofDetail === "This doesn't seem to be my neighborhood"} onClick={() => setIncorrectRoofDetail("This doesn't seem to be my neighborhood")}/>This doesn't seem to be my neighborhood</span>
                <span><input type="radio" checked={incorrectRoofDetail === "Other"} onClick={() => setIncorrectRoofDetail("Other")}/>Other</span>
                { incorrectRoofDetail === "Other" &&
                    <span>
                        <InputTextarea rows={3} value={incorrectRoofDetailOther} onChange={(e) => setIncorrectRoofDetailOther(e.target.value)} />
                    </span>
                }
                { ! props.isArizon && <div className="continue-button" onClick={() => { setStep(1); }}><span>Continue</span><span>→</span></div>}
            </div>
            }
        </div>}
        { roofConfirmation !== "" && (props.isArizona || step === 1 ) && !showBlockMessage && 
        <div className="section form">

            <div className="field first-name">
                <InputText disabled={saving} value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
            </div>
            <div className="field last-name">
                <InputText disabled={saving} value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
            </div>

            <div className="field email">
                <InputText disabled={saving} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail Address"/>
            </div>
            <div className="field mobile">
                <InputMask disabled={saving} autoClear={false} mask="(999) 999-9999" value={mobile} placeholder="Phone Number" unmask={true} onChange={(e) => setMobile(cleanPhone(e.value))}></InputMask>
            </div>

            <div className={`submit-button ${props.isArizona ? '' : 'not-arizona'}`} onClick={submitForm} style={saving ? { opacity: 0.5 } : {}}></div>

            { saving && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}

            { errorMessage !== "" && 
                <div className="error-message">{errorMessage}</div>
            }
        </div>
        }
        <Footer/>
    </div>;
}