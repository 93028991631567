import './Header.css';
import './Header.13inch.css';
import './Header.mobile.css';

export default function Header(props){

    return <div className="header">
        <div className="header-left">
            <div className="elevation-logo"></div>
            <div className="title">&nbsp;</div>
        </div>
        { (props.name || "") !== "" && 
        <div className="header-right">
            <div className="name">{props.name}</div>
            <div className="avatar"></div>
        </div>
        }
    </div>
}