import { useState, useRef } from 'react';

import './style.css';

// drag drop file component
export function DragDropFile(props) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);
  
  // handle drag events
  const handleDrag = function(e) {
    console.log("handleDrag", e.type)
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e) {
    console.log("handleDrop", e.type)
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        props.onFileSelected(e.dataTransfer.files[0]);
    }
  };
  
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.onFileSelected(e.target.files[0]);
    }
  };
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  
  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div>
            <button className="upload-button" onClick={onButtonClick}>
                <i className="pi pi-cloud-upload"  style={{ color: '#06285e', fontSize: '2.5rem' }}></i>
                <p className='title'>Browse Files or Take a Photo</p>
            </button>
            <div className='subtitle'>Drag and drop your file here</div>
            
        </div> 
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>
  );
};