import { Steps } from "primereact/steps";
import "./WizardSteps.css";

export default function WizardSteps(props) {
  return (
    <div className="wizard-steps">
      <Steps
        activeIndex={props.current}
        model={[
          {
            label: "Confirm",
          },
          {
            label: "You",
          },
          {
            label: "Homeowner",
          },
          {
            label: "Roof",
          },
          {
            label: "Batteries",
          },
          {
            label: "Monthly Bill",
          },
        ]}
      />
    </div>
  );
}
