import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { BrowserRouter } from 'react-router-dom';
import Loader from './shared_components/Loader/Loader';


const root = ReactDOM.createRoot(document.getElementById('root'));


const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <Loader/>;
    case Status.FAILURE:
      return <b>Error loading Google Mpas</b>;
    case Status.SUCCESS:
      return <App/>;
    default:
      return <Loader/>;
  }
};



root.render(
  <BrowserRouter>
    <Wrapper apiKey={"AIzaSyDv8IEE5EjtMWEPWsakbDWDxliUopc0fJk"} render={render} libraries={["places"]}/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
