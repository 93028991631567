import './Loader.css';
import './Loader.mobile.css';

import { ProgressBar } from 'primereact/progressbar';

export default function Loader(){

    return <div className="loader">
        <div className="logo"></div>      
        <div className='progress'>
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
        </div>  
        
    </div>
}