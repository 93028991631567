import './ClickRouter.css';
import './ClickRouter.mobile.css';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';

import config from '../../config.json';
import amplitude from 'amplitude-js';
   

export default function ClickRouter(){

    const navigate = useNavigate();

    useEffect(()=>{
        const executeRouting = () => {
            const params = new URLSearchParams(window.location.search);
            let redirect = "/";
            amplitude.getInstance().init(config.AMPLITUDE_KEY);
            amplitude.getInstance().logEvent("Click Captured", { params: window.location.search, referer: params.get("referer") });
            if(params.has("referer")){
                const referer = params.get("referer")
                if(referer === "programmatic"){
                    redirect = "/lp/programmatic";
                }
                else if(referer === "google"){
                    redirect = "/lp/google";
                }
                else if(referer === "facebook"){
                    localStorage.setItem("source", "Facebook");
                    const paramsJSON = {}
                    Array.from(params.entries()).forEach(p => paramsJSON[p[0]] = p[1]);
                    localStorage.setItem("click-params", JSON.stringify(paramsJSON));
                    redirect = "/";
                }
                else if(referer === "googleec"){
                    localStorage.setItem("source", "Google");
                    redirect = "/";
                }
                else if(referer === "microsoftads"){
                    localStorage.setItem("source", "Microsoft Ads");
                    redirect = "/";
                }
                else if(referer === "website"){
                    const utmSource = params.get("utm_source") || "";
                    console.log("utmSource", utmSource);
                    let source = "Elevation Website"
                    if(utmSource !== ""){
                        if(utmSource.toLowerCase() === "adwords"){
                            source = "Google";
                        }
                        else {
                            source = utmSource;
                        }
                    }
                    else {
                        const originalSource = params.get("original_source") || "";
                        if(originalSource === "https://r.search.yahoo.com/") source = "Yahoo";
                        else if(originalSource === "https://search.yahoo.com/") source = "Yahoo";
                        else if(originalSource === "https://www.bing.com/") source = "Bing";
                        else if(originalSource === "https://www.google.com/") source = "Google";
                    }
                    localStorage.setItem("source", source);
                    redirect = "/";
                }
            }
            else {
                console.log("Catch here");
                const utmSource = params.get("utm_source") || "";
                    console.log("utmSource", utmSource);
                    let source = "Elevation Website"
                    if(utmSource !== ""){
                        if(utmSource.toLowerCase() === "adwords"){
                            source = "Google";
                        }
                        else {
                            source = utmSource;
                        }
                    }
                    else {
                        const originalSource = params.get("original_source") || "";
                        if(originalSource === "https://r.search.yahoo.com/") source = "Yahoo";
                        else if(originalSource === "https://search.yahoo.com/") source = "Yahoo";
                        else if(originalSource === "https://www.bing.com/") source = "Bing";
                        else if(originalSource === "https://www.google.com/") source = "Google";
                    }
                    console.log("source", source);
                    localStorage.setItem("source", source);
                    redirect = "/";
            }
            
            navigate(redirect);
        }
        
        executeRouting();
        
    },[navigate]);

    return <Loader/>
}