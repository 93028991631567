import { BaseService } from './_BaseService';
import axios from 'axios';

export class QuoteService extends BaseService {
    start = async (quote) => {
        return await this.post(`start`, quote);
    }
    addPropertyData = async (quote) => {
        return await this.post(`add-property-data`, quote);
    }

    getUploadURL = async (path, type) => {
        return await this.post(`get-upload-url `, { path, type });
    }

    patchQuote = async (quoteId, op, data) => {
        return await this.post(`quotes/${quoteId}/${op}`,data);
    }

    getQuote = async (quoteId) => {
        return await this.get(`quotes/${quoteId}`);
    }

    login = async (login, password) => {
        return await this.post(`login`, {login, password});
    }

    upload = async (url, file, onProgress) => {
        const options = {
            params: { Key: file.name, ContentType: file.type },
            headers: { 'Content-Type': file.type },
            onUploadProgress: (progressEvent) => {
                const percentage = (progressEvent.loaded * 100) / progressEvent.total;
                if(onProgress){
                    onProgress(percentage);
                }
              },
        };
          
        await axios.put(url, file, options);
    }
}