import { useEffect, useRef, useState } from 'react';
import Footer from '../../shared_components/Footer/Footer';
import Header from '../../shared_components/Header/Header';
import './QuoteScreen.css';
import './QuoteScreen_13inch.css';
import './QuoteScreen_mobile.css';

import { Password } from 'primereact/password';
import { QuoteService } from '../../services/QuoteService';

import config from '../../config.json';
import amplitude from 'amplitude-js';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';

export default function QuoteScreen(props)
{
    amplitude.getInstance().init(config.AMPLITUDE_KEY);
    const toast = useRef(null);
    const [password, setPassword] = useState("");
    useEffect(()=>{
        const myLatLng = props.quote?.address?.googleData?.geometry?.location;
        
        const map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 20,
            center: myLatLng,
            mapTypeId: window.google.maps.MapTypeId.SATELLITE,
            disableDefaultUI: true,
            tilt: 0
        });

        new window.google.maps.Marker({
            position: myLatLng,
            map
        });

    },[props.quote]);

    const [savingPassword, setSavingPassword] = useState(false);
    const savePassword = async () => {

        try {
            if(!savingPassword && password !== ""){
                setSavingPassword(true);
                const service = new QuoteService();
                await service.patchQuote(props.quote.id, "password", { password });
                amplitude.getInstance().logEvent("Password Set");
                setPassword("");
                setSavingPassword(false);
                toast.current.show({severity:'success', summary: 'Password set', detail:'To access your solar quotes in the future, user the "ALREADY HAVE A QUOTE" link in the initial screen.', life: 10000});
            }
        } catch (error) {
            console.log(error);
        }       
    }

    const [electricbillvalue, setElectricBillValue] = useState(props.quote?.property?.electric_bill_value);
    const [changingValue, setChangingValue] = useState(false);
    const [savingChangedValue, setSavingChangedValue] = useState(false);
    const updateBillValue = async () => {
        try {
            setSavingChangedValue(true);
            const property = Object.assign(props.quote.property, {electric_bill_value: electricbillvalue});
            const service = new QuoteService();
            const _quote = await service.patchQuote(props.quote.id, "billvalueupdated", { property });
            await props.onQuoteUpdate(_quote);
            setChangingValue(false);
            setSavingChangedValue(false);    
        } catch (error) {
            console.log(error);
            setSavingChangedValue(false);
        }
        toast.current.show({severity:'success', summary: 'Done!', detail:'Average monthly bill value updated', life: 10000});
    }

    // --- Upload feature ---
    const hiddenFileInput = useRef(null);
    // const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileInput = (e) => {
        // setSelectedFile(e.target.files[0]);
        uploadFile(e.target.files[0]);
    }

    const uploadFile = async (file) => {

        try {
            if(file)
            {
                console.log("file", file)
                setUploadProgress(0);
                setUploading(true);
                const service = new QuoteService();
                const url = await service.getUploadURL(props.quote.id + "/" + file.name, file.type);
                await service.upload(url, file, (pct) => setUploadProgress(parseInt(pct, 10)));    

                const uploadBill = { fileName: file.name, url: url.split("?")[0] };
                
                const property = Object.assign(props.quote.property, {uploadBill: uploadBill})
                await service.patchQuote(props.quote.id, "billupload", { property });
            
                amplitude.getInstance().logEvent("Bill Uploaded", uploadBill);
                setUploading(false);
            }
            
        } catch (error) {
            console.log(error);
        }       
    }
    // ----------------------

    const [selectingAppointmentDate, setSelectingAppointmentDate] = useState(false);
    const [savingAppointmentDate, setSavingAppointmentDate] = useState(false);
    const [desiredDate, setDesiredDate] = useState(null);
    const updateDesiredDate = async () => {
        try {
            setSavingAppointmentDate(true);
            const service = new QuoteService();
            const _quote = await service.patchQuote(props.quote.id, "appointmentdate", { desiredDate });
            await props.onQuoteUpdate(_quote);
            setSelectingAppointmentDate(false);
            setSavingAppointmentDate(false);    
        } catch (error) {
            console.log(error);
            setSavingAppointmentDate(false);
        }
        toast.current.show({severity:'success', summary: 'Done!', detail:'We will schedule your call', life: 10000});
    }

    return <div className="screen quote">
        <Header name={props.quote?.person?.firstName}/>
        <Toast ref={toast} />
        <div className="section main">
            <div className="title">We will call you within 24 hours</div>
            <div className="sub-title">We're excited to help you realize your homes solar potential. Look out for a call from us soon to set up your consultation</div>
            <div className="widgets">
                <div className="widget property-details">
                    <div className="widget-top">
                        <div className="widget-title">Property Details</div>
                        <div className="address">
                        {props.quote?.address?.address_line1}<br/>
                        {props.quote?.address?.locality || props.quote?.address?.county}, {props.quote?.address?.state_code} {props.quote?.address?.postal_code}
                        </div>
                        <div id="map" className="roof-image"></div>
                        { !changingValue && <>
                            <div className="average-montly-bill">Average monthly bill: <b>${Intl.NumberFormat('en-US').format(props.quote?.property?.electric_bill_value)}</b></div>
                            
                            <div className="average-montly-bill-actions">
                                <div className="average-montly-bill-action action" onClick={() => setChangingValue(true)}>Change</div>
                                <div className="average-montly-bill-action divider">|</div>
                                <div className="average-montly-bill-action action" onClick={() => hiddenFileInput.current.click()}>Upload Bill</div>
                                { uploading && <div className="question-description">Uploading file: {uploadProgress}%</div> }
                                <input type="file" ref={hiddenFileInput} onChange={handleFileInput} style={{display: 'none'}}/>
                            </div>
                            </>
                        }
                        { changingValue &&
                        <div className='average-montly-bill-actions valuechange'>
                            <InputNumber disabled={savingChangedValue} prefix='$ ' value={electricbillvalue} onValueChange={(e) => setElectricBillValue(e.value)} />
                            <div className='links'>
                                <div className="average-montly-bill-action action" onClick={updateBillValue}>Save</div>
                                <div className="average-montly-bill-action divider">|</div>
                                <div className="average-montly-bill-action action" onClick={() => setChangingValue(false)}>Cancel</div>
                            </div>
                        </div>}
                        { savingChangedValue && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    </div>
                    { false &&
                        <div className="widget-bottom">
                            <div className="savings">
                                <div className="savings-detail label">25-year savings:</div>
                                <div className="savings-detail value">${Intl.NumberFormat('en-US').format(props.quote?.ownpower?.TwentyFiveYearsSavings)}</div>
                            </div>
                        </div>
                     }
                </div>
                { false &&
                <div className="widget estimated-savings">
                    <div className="widget-top">
                        <div className="widget-title">Estimated Savings</div>
                        <div className="saving-label">Your Current Bill</div>
                        <div className="saving-value">${Intl.NumberFormat('en-US').format(props.quote?.ownpower?.MonthlyBill)}</div>
                        <div className="saving-label">Your New Bill</div>
                        <div className="saving-value">${Intl.NumberFormat('en-US').format(props.quote?.ownpower?.MonthlyPayment)}</div>
                        <div className="saving-label">Your 25-year net savings</div>
                        <div className="saving-value">${Intl.NumberFormat('en-US').format(props.quote?.ownpower?.TwentyFiveYearsSavings)}</div>
                    </div>
                    <div className="widget-bottom">
                        <div className="saving-disclaimer">In the U.S., electricity prices go up <b>3.5% / year</b> in average. With solar, you avoid 30-years of rate increases.</div>
                    </div>
                </div>}
                <div className="widget advisor">
                    <div className="widget-top">
                        <div className="widget-title">Meet Your Advisor</div>
                        <div className="advisor-panel">
                            <div className="advisor-picture"></div>
                            <div className="advisor-name">
                                <span>{ props.quote?.advisor?.name }</span>
                            </div>
                        </div>
                        <div className="advisor-message">Contact your advisor anytime via email or schedule a call now!</div>
                    </div>
                    <div className="widget-bottom">                    
                        { !selectingAppointmentDate && <>
                            <div className="widget-buttom blue"><a href={`mailto: ${props.quote?.advisor?.email}`}>Send an E-mail</a></div>
                            <div className="widget-buttom gree" onClick={() => setSelectingAppointmentDate(true) }>Schedule a call</div>
                            </>
                        }
                        { selectingAppointmentDate && <>
                            <div className="calender-field">
                                <Calendar style={{width: '100%'}} value={desiredDate} onChange={(e) => setDesiredDate(e.value)} showTime showSeconds={false} hourFormat="12" placeholder='Click to select date'/>
                            </div>
                            <div className="widget-buttom gree" onClick={() => updateDesiredDate() }>Save</div>
                            { savingAppointmentDate && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                            </>
                        }
                    </div>
                </div>
                <div className="widget more">
                    <div className="widget-child next">
                        <div className="widget-title">Coming Up Next...</div>
                        <div className='content'>
                            <b>The Elevation Energy Advisor team will be reaching out to you soon.</b> To help us get you a quote more quickly, please have your utility bill available to share.
                        </div>

                    </div>
                    <div className="widget-child password">
                        <div className="widget-title">Set a password</div>
                        <div className='content'>
                        Set a password and access your solar quotes in the future. (Optional)
                        </div>
                        <div className='forms'>
                            <Password value={password} feedback={false} onChange={(e) => setPassword(e.target.value)} />
                            <div className='button' onClick={savePassword} style={savingPassword || password === "" ? { opacity: 0.5, cursor: 'default' } : {}}></div>
                        </div>
                        { savingPassword && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    </div>
                </div>
            </div>
        </div>
        
        <div className="section other-offers">
            { false && <div className="offers deal">
                <div className="title">Deal of The Day</div>
                <div className="content">
                    <div className="image"></div>
                    <div className="text">
                        When you add <b>Curb</b> to your Solar purchase, you instantly get <b>15% off!</b> Type promo code <b>CURB15</b> at checkout. Hurry up! This promotion expires <b>11/6/2022</b>.
                    </div>
                </div>
            </div>}
            <div className="offers referrals" onClick={() => window.open('https://www.elevation-referral-program.com/','_blank')}>
                <div className="title">Refer a Friend</div>
                <div className="content">
                    <div className="image"></div>
                    <div className="text">
                    We offer the best referral program ever from an energy solution provider. Refer your friend and earn <b>$500 cash!</b> No draws, no hidden cuts, no store credit!
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>;
}