import './Footer.asu.css';
import './Footer.default.css';
import './Footer.mobile.css';

export default function Footer(){


    return <div className="footer">
        <div className="logo"></div>
        <div className="info">
            <span>Elevation</span>
            <span className='divider'>|</span>
            <span><a href={"tel: +18662584381"}>(866) 258 4381</a></span>
            <span className='divider'>|</span>
            <span><a href={"https://www.poweredbyelevation.com"} rel="noreferrer" target={"_blank"}>poweredbyelevation.com</a></span>
        </div>
        <div className="links">
            <div className='link youtube' onClick={() => window.open("https://www.youtube.com/c/ElevationSolarGilbert")}></div>
            <div className='link facebook' onClick={() => window.open("https://www.facebook.com/poweredbyelevation", "_blank")}></div>
            <div className='link linkedin' onClick={() => window.open("https://www.linkedin.com/company/poweredbyelevation/", "_blank")}></div>
        </div>
    </div>
}