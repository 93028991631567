import { useEffect, useState } from "react";
import axios from "axios";
import IntroScreen from "./screens/intro/IntroScreen";
import AboutYouScreen from "./screens/about-you/AboutYouScreen";
import AboutThePropertyScreen from "./screens/about-the-property/AboutThePropertyScreen";
import QuoteScreen from "./screens/quote/QuoteScreen";

import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { QuoteService } from "./services/QuoteService";
import ClickRouter from "./shared_components/ClickRouter/ClickRouter";

import config from "./config.json";
import amplitude from "amplitude-js";
import Loader from "./shared_components/Loader/Loader";

function App() {
  amplitude.getInstance().init(config.AMPLITUDE_KEY);

  const STEPS = {
    INTRO: 1,
    ABOUTYOU: 2,
    ABOUTPROPERTY: 3,
    QUOTE: 4,
  };

  const [quote, setQuote] = useState({ step: STEPS.INTRO });
  const [location, setLocation] = useState(null);

  const navigate = useNavigate();
  const doNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const onAuthenticate = (_quote) => {
    amplitude.getInstance().setUserProperties(quote.person);
    amplitude.getInstance().logEvent("Login Successfull");
    localStorage.setItem("quoteid", JSON.stringify(_quote.id));
    setQuote(_quote);
    doNavigate(`/quote`);
  };

  const selectAddress = (address) => {
    const _quote = Object.assign({}, quote);
    _quote.address = address;
    _quote.step = STEPS.ABOUTYOU;

    amplitude.getInstance().logEvent("Address Submitted");
    localStorage.setItem("address", JSON.stringify(address));
    setQuote(_quote);
    doNavigate(`/you`);
  };

  const setPersonData = async (personData) => {
    let _quote = Object.assign(quote);
    _quote.person = personData;
    _quote.step = STEPS.ABOUTPROPERTY;
    if ((_quote.source || "") === "") {
      _quote.source = localStorage.getItem("source") || "";
    }

    if ((_quote.clickParams || "") === "") {
      _quote.clickParams = JSON.parse(
        localStorage.getItem("click-params") || "{}"
      );
    }

    try {
      const service = new QuoteService();
      _quote = await service.start(_quote);

      amplitude.getInstance().setUserProperties(personData);
      amplitude.getInstance().logEvent("Person Data Submitted", personData);
      localStorage.setItem("quoteid", JSON.stringify(_quote.id));
    } catch (error) {
      console.log("Error");
      console.log(error);
      amplitude.getInstance().logEvent("Person Data Submitted", personData);
      amplitude.getInstance().logEvent("Error", {
        action: "setPersonData",
        data: personData,
        err: error,
      });
    }

    if(_quote.address.state_code === "AZ" || _quote.address.state_code === "NV"){
      setQuote(_quote);
      doNavigate(`/property`);
      return false;
    }
    else {
      return true
    }
    
  };

  const setPropertyData = async (propertyData) => {
    let _quote = Object.assign({}, quote);
    _quote.property = propertyData;
    _quote.step = STEPS.QUOTE;
    try {
      const service = new QuoteService();
      _quote = await service.addPropertyData(_quote);
      console.log(_quote);
      amplitude.getInstance().logEvent("Property Data Submitted", propertyData);
    } catch (error) {
      console.log("Error");
      console.log(error);
      amplitude.getInstance().logEvent("Property Data Submitted", propertyData);
      amplitude.getInstance().logEvent("Error", {
        action: "setPropertyData",
        data: propertyData,
        err: error,
      });
    }

    setQuote(_quote);
    doNavigate(`/quote`);
  };

  const setPassword = () => {
    console.log("set password");
  };
  const scheduleCall = () => {
    console.log("schedule call");
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      amplitude.getInstance().init(config.AMPLITUDE_KEY);
      amplitude.getInstance().logEvent("App Loaded");

      // Refresh handling code
      const path = window.location.pathname;
      console.log(path);
      if (path === "/") {
        //Intro Screen
        console.log("Resetting local storage");
        localStorage.removeItem("quoteid");
        localStorage.removeItem("address");
        setLoading(false);
      } else if (path === "/you") {
        const quoteId = localStorage.getItem("quoteid") || "";
        if (quoteId !== "") {
          const service = new QuoteService();
          const _quote = await service.getQuote(JSON.parse(quoteId));
          if (_quote.id) {
            setQuote(_quote);
            setLoading(false);
            return;
          }
        }

        const address = localStorage.getItem("address") || "";
        if (address !== "") {
          const _quote = Object.assign({}, quote);
          _quote.address = address;
          _quote.step = 2;
          setQuote(_quote);
          setLoading(false);
        } else {
          localStorage.removeItem("quoteid");
          localStorage.removeItem("address");
          window.location.href = "/";
        }
      } else if (
        path === "/address" ||
        path === "/property" ||
        path === "/quote"
      ) {
        const quoteId = localStorage.getItem("quoteid") || "";
        if (quoteId !== "") {
          const service = new QuoteService();
          const _quote = await service.getQuote(JSON.parse(quoteId));
          if (_quote.id) {
            console.log(_quote);
            setQuote(_quote);
            setLoading(false);
          } else {
            localStorage.removeItem("quoteid");
            localStorage.removeItem("address");
            window.location.href = "/";
          }
        } else {
          localStorage.removeItem("quoteid");
          localStorage.removeItem("address");
          window.location.href = "/";
        }
      } else if (path === "/click" || path === "/get-estimate") {
        // Do nothing
        setLoading(false);
      } else if (path.startsWith("/rq/")) {
        const quoteId = path.split("/rq/")[1];

        if ((quoteId || "") !== "") {
          localStorage.setItem("quoteid", JSON.stringify(quoteId));
          const service = new QuoteService();
          const _quote = await service.getQuote(quoteId);
          if (_quote.id) {
            window.location.href =
              _quote.step === 3
                ? "/property"
                : _quote.step === 4
                ? "/quote"
                : _quote.step === 2
                ? "/you"
                : _quote.step === 1
                ? "/address"
                : "/";
          } else {
            localStorage.removeItem("quoteid");
            localStorage.removeItem("address");
            window.location.href = "/";
          }
        } else {
          localStorage.removeItem("quoteid");
          localStorage.removeItem("address");
          window.location.href = "/";
        }
      } else {
        localStorage.removeItem("quoteid");
        localStorage.removeItem("address");
        window.location.href = "/";
      }

      const getUserLocationFromAPI = async () => {
        try {
          const apiURL = config.GEOLOCATION_API_URL;
          const response = await axios.get(apiURL);
          setLocation(response.data);
        } catch (error) {
          console.log("Something went wrong getting Geolocation from API!");
        }
      };

      getUserLocationFromAPI();
    };

    loadInitialData();
    return () => {
      // this now gets called when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }

  const isArizona = location?.country_code === "US" && location?.region_iso_code === "AZ";

  return (
    <div className={`App default`}>
      <Routes>
        <Route
          path="/"
          element={
            <IntroScreen
              quote={quote}
              onAddressSelected={(address) => selectAddress(address)}
              onAuthenticate={(recoveredQuote) =>
                onAuthenticate(recoveredQuote)
              }
              isArizona={isArizona}
            />
          }
        />
        <Route
          path="/address"
          element={
            <IntroScreen
              quote={quote}
              onAddressSelected={(address) => selectAddress(address)}
              onAuthenticate={(recoveredQuote) =>
                onAuthenticate(recoveredQuote)
              }
              isArizona={isArizona}
            />
          }
        />
        <Route
          path="/you"
          element={
            <AboutYouScreen
              quote={quote}
              onFormFilled={(personData) => setPersonData(personData)}
              isArizona={isArizona}
            />
          }
        />
        <Route
          path="/property"
          element={
            <AboutThePropertyScreen
              quote={quote}
              onFormFilled={(propertyData) => setPropertyData(propertyData)}
              isArizona={isArizona}
            />
          }
        />
        <Route
          path="/quote"
          element={
            <QuoteScreen
              quote={quote}
              onPasswordSet={(password) => setPassword(password)}
              onCallScheduled={(callDate) => scheduleCall(callDate)}
              onQuoteUpdate={(updatedQuote) => setQuote(updatedQuote)}
              isArizona={isArizona}
            />
          }
        />
        <Route path="/click" element={<ClickRouter />} />
        <Route path="/get-estimate" element={<ClickRouter />} />
        <Route
          path="/lp/programmatic"
          element={<IntroScreen mode={"LP"} source={"Programmatic"} isArizona={isArizona}/>}
        />
        <Route
          path="/lp/google"
          element={<IntroScreen mode={"LP"} source={"Google"} isArizona={isArizona}/>}
        />
        <Route
          path="/lp/facebook"
          element={<IntroScreen mode={"LP"} source={"Facebook"} isArizona={isArizona}/>}
        />
      </Routes>
    </div>
  );
}

export default App;
