import { useState, useRef } from "react";
import "./AboutThePropertyScreen.css";
import "./AboutThePropertyScreen.mobile.css";

import { InputNumber } from "primereact/inputnumber";
import Header from "../../shared_components/Header/Header";
import Footer from "../../shared_components/Footer/Footer";

import { QuoteService } from "../../services/QuoteService";

import { ProgressBar } from "primereact/progressbar";

import config from "../../config.json";
import amplitude from "amplitude-js";
import { DragDropFile } from "../../shared_components/DragDropFile/DragDropFile";
import WizardSteps from "../../components/wizard-steps/WizardSteps";

export default function AboutThePropertyScreen(props) {
  amplitude.getInstance().init(config.AMPLITUDE_KEY);
  const [propertyData, setPropertyData] = useState({});

  const [errorMessage, setErrorMessage] = useState("");

  const setAnswer = (question, value) => {
    const _propertyData = Object.assign({}, propertyData);
    _propertyData[question] = value;
    setPropertyData(_propertyData);
  };

  const getAnswer = (quesion) => {
    return propertyData[quesion] || "";
  };

  const isDataValid = () => {
    if (
      (propertyData["homeowner"] || "") === "" ||
      ((propertyData["solar_journey"] || "") === "" && props.isArizona) ||
      (propertyData["old_roof"] || "") === "" ||
      (propertyData["batteries"] || "") === "" ||
      ((propertyData["batteries"] || "") === "yes" &&
        (propertyData["batteries_details"] || "") === "") ||
      (propertyData["electric_bill_value"] || "") === ""
    ) {
      setErrorMessage("Please answer all the questions");
      return false;
    }

    return true;
  };

  const [saving, setSaving] = useState(false);
  const submitAnswers = async () => {
    if (!saving) {
      try {
        if (isDataValid()) {
          setSaving(true);
          await props.onFormFilled(propertyData);
          setSaving(false);
        }
      } catch (error) {
        setSaving(false);
        window.alert("" + error);
      }
    }
  };

  // --- Upload feature ---
  const hiddenFileInput = useRef(null);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileInput = (e) => {
    // setSelectedFile(e.target.files[0]);
    uploadFile(e.target.files[0]);
  };

  const clearUploaded = () => {
    const _propertyData = Object.assign({}, propertyData);
    _propertyData.uploadBill = null;
    setPropertyData(_propertyData);
  };

  const uploadFile = async (file) => {
    try {
      console.log("file", file);
      if (file) {
        setUploadProgress(0);
        setUploading(true);
        const service = new QuoteService();
        const url = await service.getUploadURL(file.name, file.type);
        console.log(url);
        await service.upload(url, file, (pct) =>
          setUploadProgress(parseInt(pct, 10))
        );

        const _propertyData = Object.assign({}, propertyData);
        _propertyData.uploadBill = {
          fileName: file.name,
          url: url.split("?")[0],
        };
        setPropertyData(_propertyData);

        amplitude
          .getInstance()
          .logEvent("Bill Uploaded", _propertyData.uploadBill);
        setUploading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // ----------------------

  const [step, setStep] = useState(2)

  return (
    <div
      className={`screen about-the-property ${
        props.isArizona ? "" : "not-arizona"
      }`}
    >
      <Header
        name={
          props.quote?.person?.firstName + " " + props.quote?.person?.lastName
        }
      />
      { !props.isArizona && <WizardSteps current={step}/>}
      { props.isArizona && <div className="section title">
        Provide us with additional information to provide you with a
        personalized proposal
      </div>}
      { (props.isArizona || step === 2 ) &&  <div className="section question">
        <div className="question-text">Are you the homeowner?</div>
        <div className="buttons">
          <div
            className={`button yes ${
              getAnswer(`homeowner`) === "yes" ? "set" : "notset"
            }`}
            onClick={() => { setAnswer("homeowner", "yes"); setStep(3); }}
          >
            <i className="pi pi-check" />
            <span>Yes</span>
          </div>
          <div
            className={`button no ${
              getAnswer(`homeowner`) === "no" ? "set" : "notset"
            }`}
            onClick={() => { setAnswer("homeowner", "no"); setStep(3); }}
          >
            <i className="pi pi-times" />
            <span>No</span>
          </div>
        </div>
      </div>}
      { props.isArizona && <div className="section question">
        <div className="question-text">
          Where are you at in your solar journey?
        </div>
        <div className="batteries-detail-question-options">
          <div className="batteries-detail-question-option">
            <input
              type={"radio"}
              checked={
                getAnswer(`solar_journey`) ===
                "I am still deciding if solar is right for me."
              }
              onClick={() =>
                setAnswer(
                  `solar_journey`,
                  `I am still deciding if solar is right for me.`
                )
              }
            />
            <div className="label">
              I am still deciding if solar is right for me.
            </div>
          </div>
          <div className="batteries-detail-question-option">
            <input
              type={"radio"}
              checked={
                getAnswer(`solar_journey`) ===
                "I am going solar but still deciding which company is best."
              }
              onClick={() =>
                setAnswer(
                  `solar_journey`,
                  `I am going solar but still deciding which company is best.`
                )
              }
            />
            <div className="label">
              I am going solar but still deciding which company is best.
            </div>
          </div>
          <div className="batteries-detail-question-option">
            <input
              type={"radio"}
              checked={
                getAnswer(`solar_journey`) ===
                "I am very far along in my search and know exactly what I want."
              }
              onClick={() =>
                setAnswer(
                  `solar_journey`,
                  `I am very far along in my search and know exactly what I want.`
                )
              }
            />
            <div className="label">
              I am very far along in my search and know exactly what I want.
            </div>
          </div>
        </div>
      </div>}
      { (props.isArizona || step === 3 ) &&  <div className="section question">
        <div className="question-text">Is your roof 20+ years old?</div>
        <div className="buttons">
          <div
            className={`button yes ${
              getAnswer(`old_roof`) === "yes" ? "set" : "notset"
            }`}
            onClick={() => { setAnswer("old_roof", "yes"); setStep(4); }}
          >
            <i className="pi pi-check" />
            <span>Yes</span>
          </div>
          <div
            className={`button no ${
              getAnswer(`old_roof`) === "no" ? "set" : "notset"
            }`}
            onClick={() => { setAnswer("old_roof", "no"); setStep(4); }}
          >
            <i className="pi pi-times" />
            <span>No</span>
          </div>
        </div>
      </div>}
      { (props.isArizona || step === 4 ) &&  <div className="section question">
        <div className="question-text">
          Would you be interested in solar batteries?
        </div>
        <div className="question-description">
          Home batteries that store energy at home for later use also falls into
          this category. Energy storage usually comes with the installation of
          solar panels.
        </div>
        <div className="buttons">
          <div
            className={`button yes ${
              getAnswer(`batteries`) === "yes" ? "set" : "notset"
            }`}
            onClick={() => setAnswer("batteries", "yes")}
          >
            <i className="pi pi-check" />
            <span>Yes</span>
          </div>
          <div
            className={`button no ${
              getAnswer(`batteries`) === "no" ? "set" : "notset"
            }`}
            onClick={() => { setAnswer("batteries", "no"); setStep(5)}}
          >
            <i className="pi pi-times" />
            <span>No</span>
          </div>
        </div>
        {getAnswer(`batteries`) === "yes" && (
          <>
            <div className="question-description">
              I’m particularly interested in
            </div>
            <div className="batteries-detail-question-options">
              <div className="batteries-detail-question-option">
                <input
                  type={"radio"}
                  checked={
                    getAnswer(`batteries_details`) === "Emergency Backup Power"
                  }
                  onClick={() =>
                    setAnswer(`batteries_details`, `Emergency Backup Power`)
                  }
                />
                <div className="label">Emergency Backup Power</div>
                <div className="content">
                  Run critical appliances for a limited time in the event of a
                  power outage.
                </div>
              </div>
              <div className="batteries-detail-question-option">
                <input
                  type={"radio"}
                  checked={
                    getAnswer(`batteries_details`) === "Maximizing Savings"
                  }
                  onClick={() =>
                    setAnswer(`batteries_details`, `Maximizing Savings`)
                  }
                />
                <div className="label">Maximizing Savings</div>
                <div className="content">
                  Optimize your electril bill savings under a TOU or
                  deman-charge rate.
                </div>
              </div>
              <div className="batteries-detail-question-option">
                <input
                  type={"radio"}
                  checked={getAnswer(`batteries_details`) === "Self-Supply"}
                  onClick={() => setAnswer(`batteries_details`, `Self-Supply`)}
                />
                <div className="label">Self-Supply</div>
                <div className="content">
                  Send less energy to the grid if you live somewhere without net
                  metering and save.
                </div>
              </div>
            </div>
            { !props.isArizona && <div className="continue-button" onClick={() => { setStep(5); }}><span>Continue</span><span>→</span></div>}
          </>
        )}
      </div>}
      { (props.isArizona || step === 5 ) &&  <>
      {false && (
        <div className="section question">
          <div className="question-text">
            Are you considering other home energy provider?
          </div>
          <div className="buttons">
            <div
              className={`button yes ${
                getAnswer(`quote_from_another_installer`) === "yes"
                  ? "set"
                  : "notset"
              }`}
              onClick={() => setAnswer("quote_from_another_installer", "yes")}
            >
              <i className="pi pi-check" />
              <span>Yes</span>
            </div>
            <div
              className={`button yes ${
                getAnswer(`quote_from_another_installer`) === "no"
                  ? "set"
                  : "notset"
              }`}
              onClick={() => setAnswer("quote_from_another_installer", "no")}
            >
              <i className="pi pi-times" />
              <span>No</span>
            </div>
          </div>
        </div>
      )}
      {!props.quote.sqft && (
        <>
          <div className="section question upload">
            <div className="question-text">
              What is your property size (sq. ft)?
            </div>
            <div className="eletric-bill-fields">
              <InputNumber
                disabled={saving}
                suffix=" sqft"
                value={propertyData.sqft}
                onValueChange={(e) => setAnswer("sqft", e.value)}
              />
            </div>
          </div>
        </>
      )}
      <div className="section question upload">
        <div className="question-text">
          What is your average monthly electric bill?
        </div>
        <div className="eletric-bill-fields">
          <InputNumber
            disabled={saving}
            prefix="$ "
            value={propertyData.electric_bill_value}
            onValueChange={(e) => setAnswer("electric_bill_value", e.value)}
          />
        </div>
        <div className="question-text upload-link">
          Please click below to upload a copy of your most recent utility
          statement. This will allow us to create a customized solar proposal
          for your home.
        </div>
        {!uploading && (propertyData.uploadBill?.fileName || "") !== "" && (
          <div className="question-description uploaded">
            <div>
              Uploaded Bill:{" "}
              <a
                href={propertyData.uploadBill?.url}
                target={"_blank"}
                rel="noreferrer"
              >
                {propertyData.uploadBill?.fileName}
              </a>
            </div>
            <div className="icons">
              <i className="pi pi-times" onClick={() => clearUploaded()}></i>
            </div>
          </div>
        )}
        {uploading && (
          <div className="question-description">
            Uploading file: {uploadProgress}%
          </div>
        )}
        {props.isArizona &&<input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileInput}
          style={{ display: "none" }}
        />}
        {props.isArizona && !uploading && (propertyData.uploadBill?.fileName || "") === "" && (
          <DragDropFile onFileSelected={uploadFile} />
        )}
      </div>
      <div className="section buttons">
        <div
          id="ecommerce-conversion"
          className="submit-button"
          onClick={submitAnswers}
          style={saving ? { opacity: 0.5 } : {}}
        ></div>
        {saving && (
          <ProgressBar
            mode="indeterminate"
            style={{ height: "6px" }}
          ></ProgressBar>
        )}
        {errorMessage !== "" && (
          <div className="error-message">{errorMessage}</div>
        )}
      </div>
      </>}
      <Footer />
    </div>
  );
}
